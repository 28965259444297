@font-face {
  font-family: "Mikado";
  src: url("./assets/fonts/Mikado/Mikado-Web-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Mikado";
  src: url("./assets/fonts/Mikado/Mikado-Web-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
